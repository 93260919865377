<template>
    <div class="section-padding-top why-choose-area ">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Section Title Start -->
                    <div class="section-title " data-aos="fade-up" data-aos-delay="300">
                        <h2 >{{$t('Contents.WhyChoseUs')}}</h2>
                        <div class="c-line"></div>
                    </div>
                    <!-- Section Title End -->
                </div>

                <div class="col-12 mt-40">
                    <div class="service-inner-container">
                        <div class="service-block" data-aos="fade-up" data-aos-delay="300" v-for="(service, index) in services" :key="index">
                            <div class="inner-box">
                                <h5 class="title mt-20">
                                    <div class="icon">
                                        <!-- <i class="lni lni-support"></i> -->
                                        <i :class="service.icon"></i>
                                    </div>
                                    <a :href="'#'+service.title" class="bt-20">{{ service.title }}</a>
                                    
                                </h5>
                                <p class="mt-20"> {{ service.desc }}</p>
                                 <div class="inner-box-bottom">
                                    <!-- <div class="icon">
                                        <i :class="service.icon"></i>
                                    </div> -->
                                    <!-- <n-link to="/about" class="more">more details</n-link> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                // team: this.$t("Team"),
                // services: []
            }
        },
        computed: {
            services() {
                return [
                    {
                        title: this.$t('Team'),
                        desc: this.$t('Contents.WhyChoseUsDetails.OurTeamIsVeryFriendlySupportive'),
                        icon: "lni lni-users"
                    },
                    {
                        title: this.$t('Commitment'),
                        desc: this.$t('Contents.WhyChoseUsDetails.HighlyCommittedWithBusinessEthics'),
                        icon: "lni lni-timer"
                    },
                    {
                        title: this.$t('Solutions'),
                        desc: this.$t('Contents.WhyChoseUsDetails.ProvideCustomizedSolutionsProducts'),
                        icon: "lni lni-protection"
                    },
                    {
                        title: this.$t('Value'),
                        desc: this.$t('Contents.WhyChoseUsDetails.AffordablePricesStockFastDelivery'),
                        icon: "lni lni-tag"
                    },
                    {
                        title: this.$t('Support'),
                        desc: this.$t('Contents.WhyChoseUsDetails.BestQualityTechnicalSupport'),
                        icon: "lni lni-support"
                    }
                ]
            }
        }
    };
</script>


<style >
    .why-choose-area{
        background-color: rgba(255, 255, 255, 0.1);
    }

    .c-line{
        width: 30px;
        height: 3px;
        background-color: #208e87;
        margin: 30px auto 30px auto;

    }

    .why-choose-area {
        position: relative;
        background-color: none;
        padding-top: 60px !important;
        background-color: rgba(255, 255, 255, 0.1);
    }

    .why-choose-area::before {
        height: 100vh;
        content: '';
        position: fixed;
        z-index: -1;
        display: block;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        background-image: url("/assets/images/bg-min.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
    }
</style>